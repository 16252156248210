import * as constants from './action-types'
import instance from '../services/instance'
import { authService } from '@/bundles/Auth/factory/authServiceFactory';
import { MultiAuthReturn } from '@/bundles/Auth/models/MultiAuthReturn';
import { RootMutations } from '@/store/types/mutation-types';
import { AuthReturn } from '@/bundles/Auth/models/AuthReturn';
import { RootActions } from '@/store/types/action-types';
import { accountSettingsService } from '@/bundles/Account/factory/accountSettingsServiceFactory';
import UpdateAccountSettings from '@/bundles/Account/dto/update-account-settings';
import { ServiceFactory } from '@/services/ServiceFactory';
import { RESET_SELECTED, RESET_TABLE_OPTIONS } from './modules/tableOptions/types';
import UserCredentialsDto from '@/bundles/Auth/dto/user-credentials';

export default {
  [constants.GET_INSTALLATION_INFO] ({ commit }, { id, token }) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/workOrder/install/${id}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          const { result } = response.data
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.COMPLETE_INSTALLATION] ({ commit }, { id, payload, token }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/workOrder/install/${id}`, payload, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err: any) => {
          reject(new Error(err))
        })
    })
  },
  async [constants.LOGIN] ({ commit, dispatch }, payload: UserCredentialsDto) {
    try {
      const response = await authService.signIn(payload);

      dispatch(RootActions.SET_LOGIN_INFO, response)
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      commit(constants.TOGGLE_SPLASH_SCREEN, false);
    }
  },
  async [RootActions.LOG_IN_WITH_MAGIC_LINK] ({ dispatch, commit }, token: string) {
    try {
      const response = await authService.loginWithMagicLink(token);

      const mutationName = response instanceof MultiAuthReturn ? RootMutations.SET_TEMPORARY_AUTH_DATA : RootMutations.SET_AUTH_INFO;
      commit(mutationName, response);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  async [RootActions.SET_LOGIN_INFO] ({ commit, dispatch }, payload: AuthReturn | MultiAuthReturn) {
    const mutationName = payload instanceof MultiAuthReturn ? RootMutations.SET_TEMPORARY_AUTH_DATA : RootMutations.SET_AUTH_INFO;
    commit(mutationName, payload);
    if (!(payload instanceof MultiAuthReturn)) {
      dispatch(RootActions.FETCH_CURRENT_USER);
    }
  },
  async [RootActions.MULTI_ACCOUNT_SIGN_IN] ({ commit }, payload: { account: number }) {
    const response = await authService.multiAccountSignIn(payload);
    commit(RootMutations.SET_AUTH_INFO, response);
  },
  async [RootActions.FETCH_CURRENT_USER] ({ commit, state }) {
    const response = await authService.me();
    if (state?.activeAccount?.id !== response?.active_account?.id) {
      commit(`TableOptions/${RESET_TABLE_OPTIONS}`);
      commit(`TableOptions/${RESET_SELECTED}`);
    }
    commit(RootMutations.SET_CURRENT_USER, response);
  },
  async [RootActions.FETCH_ACCOUNT_SETTINGS] ({ commit, state }) {
    const response = await accountSettingsService.getOne(state.activeAccount.id);
    commit(RootMutations.SET_ACCOUNT_SETTING, response);
  },
  async [RootActions.UPDATE_ACCOUNT_SETTINGS] ({ commit, state }, payload: UpdateAccountSettings) {
    const response = await accountSettingsService.updateOne(state.activeAccount.id, payload);
    commit(RootMutations.SET_ACCOUNT_SETTING, response);
  },
  async [RootActions.FETCH_USER_INTEGRATIONS] ({ commit, state }) {
    const integrationsInstance = ServiceFactory.get('integrations');
    const { data } = await integrationsInstance.getMyIntegrations({ token: state.idToken });
    commit(RootMutations.SET_USER_INTEGRATIONS, data);
  },
  [RootActions.CANCEL_PENDING_REQUESTS] ({ state, commit }) {
    for (const token of state.cancelTokens) {
      if (token?.abort) {
        token?.abort();
      }
    }
    commit(RootMutations.CLEAR_CANCEL_TOKENS);
  }
}
