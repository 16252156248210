import { RateEnteredAsEnum, RateEnteredAsLabelEnum } from '@/bundles/Availabilities/types';

export const getLeaseTypes = () => [
  'NNN',
  'Gross',
  'Absolute Net',
  'Double Net',
  'Modified Gross',
  'Full Service',
  'NET'
];

export const getAgencyTypes = () => [
  'Exclusive',
  'Non-Exclusive',
  'Owner Listed',
  'Pocket',
];

export const getVacancyStatuses = () => [
  { text: 'Occupied', value: 'occupied' },
  { text: 'Vacant', value: 'vacant' },
];

export const getPriceAs = () => [
  { text: 'Total Price', value: 'totalPrice' },
  { text: 'Per Square Foot', value: 'perSf' },
];

export const getRateEnteredAs = () => [
  { text: 'Base Rent / SF ', value: RateEnteredAsEnum.per_sf },
  { text: 'Base Rent / Month ', value: RateEnteredAsEnum.per_month },
];

export const getRateEnteredAsLabel = (leaseRateEnteredAs: RateEnteredAsLabelEnum) => {
  return RateEnteredAsLabelEnum[leaseRateEnteredAs] || 'SF';
};
