






















import Vue from 'vue'

export default Vue.extend({
  name: 'MarketingTopPanelSendButtons',

  props: {
    sourceType: {
      type: String,
      default: '',
    },
    pressReleaseStatus: {
      type: String,
      default: '',
    },
    allowSend: Boolean,
    allowSendTest: Boolean
  }
})
