import { capitalize, sum } from 'lodash';
import { DealInvoiceDueTypesEnum, DealTransactionTypeEnum } from '@/bundles/Deal/types';
import { Nullable } from '@/utils/types';
import { formatJSDate, getDateDiff } from '@/utils/filters';
import { DateTime } from 'luxon';

export * from './formData';

export const getDealContactsArray = (contactsObject: Record<string, any> | undefined) => {
  if (!contactsObject) return [];

  return Object.keys(contactsObject).reduce((acc, key) => {
    return acc.concat(contactsObject[key]);
  }, []);
};

export const getDealTransactionTypeItems = () => {
  return Object.values(DealTransactionTypeEnum).map(value => {
    return { text: capitalize(value), value };
  });
};

export const getBrokersSplitInfo = (brokers, totalCommission: Nullable<number>) => {
  const commission = totalCommission || 0;
  const offTopPercentage = sum(brokers.filter(broker => broker.percentage_type === 'Off Top').map(broker => broker.percentage));
  const offTopTotal = commission * offTopPercentage / 100;
  const offRemainingTotal = commission - offTopTotal;

  return brokers.map(broker => {
    const total = broker.percentage_type === 'Off Top' ? commission : offRemainingTotal;

    return {
      broker: broker._id,
      name: broker.name,
      photo: broker.img,
      percentage: broker.percentage,
      percentage_type: broker.percentage_type,
      total: total * broker.percentage / 100,
    }
  })
};

export const getInvoiceStatusProps = (invoice) => {
  const received = invoice.payment
          .map((payment) => payment.amount)
          .reduce((accum, value) => accum + value, 0);

  const isOverdue = getDateDiff(invoice.due_date).milliseconds < 0;
  const isPaid = invoice.amount - received <= 0

  return {
    status: isPaid ? 'paid' : isOverdue ? 'overdue' : 'open',
    color: isPaid ? 'green' : isOverdue ? 'red' : '',
  };
};

export const getInvoiceAging = (date: string): { daysDiff: number, diffLabel: Nullable<string> } => {
  if (!date) {
    return {
      daysDiff: 0,
      diffLabel: null
    };
  }
  const today = DateTime.now();
  const due = DateTime.fromISO(formatJSDate(date, true) as string);
  const diff = Math.round(today.diff(due, 'days').days);
  let label: Nullable<string>;

  switch (true) {
    case (diff > 0 && diff <= 30):
      label = '1-30 days';
      break;
    case (diff > 30 && diff <= 60):
      label = '31 - 60 days';
      break;
    case (diff > 60 && diff <= 90):
      label = '61 - 90 days';
      break;
    case (diff > 90):
      label = '91+ days';
      break;
    default:
      label = null;
  }
  return {
    daysDiff: diff,
    diffLabel: label
  }
};

export function getInvoiceDueItems () {
  return [
    { text: 'Due on Receipt', value: DealInvoiceDueTypesEnum.on_receipt },
    { text: 'Net 10', value: DealInvoiceDueTypesEnum.on_10 },
    { text: 'Net 15', value: DealInvoiceDueTypesEnum.on_15 },
    { text: 'Net 30', value: DealInvoiceDueTypesEnum.on_30 },
  ];
}
