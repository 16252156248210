

























import Vue from 'vue';
import { Nullable } from '@/utils/types';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';

interface IDragAndDropArea {
  dragover: boolean;
  uploadedFiles: File[];
}

export default Vue.extend({
  name: 'DragAndDropArea',

  props: {
    acceptTypes: {
      type: String,
      default: null,
    },
    multiple: Boolean
  },

  data: (): IDragAndDropArea => ({
    dragover: false,
    uploadedFiles: [],
  }),

  watch: {
    uploadedFiles (value: File[]) {
      if (value) {
        this.setImageUrl(value);
      }
    }
  },

  mounted () {
    this.toggleClipboard(true);
  },

  beforeDestroy () {
    this.toggleClipboard(false);
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION
    }),
    onDrop (e) {
      if (this.uploadedFiles.length) {
        this.dragover = false;
        return;
      }

      this.dragover = false;
      this.onLoadHandler(e.dataTransfer.files);
    },
    onLoadHandler (files: FileList | File[]) {
      if (this.uploadedFiles.length) {
        this.uploadedFiles = [];
      }

      const acceptableTypes = this.acceptTypes?.split(', ');
      const arrayFiles = Array.from(files);
      const disallowedExtensions = ['.html', '.htm'];
      const disallowedMimeTypes = ['text/html'];

      const isAllowedType = acceptableTypes?.length && arrayFiles.every(file => {
        const fileExtension = file.name.toLowerCase().slice(file.name.lastIndexOf('.'));
        const isDisallowedExtension = disallowedExtensions.includes(fileExtension);
        const isDisallowedMimeType = disallowedMimeTypes.includes(file.type);

        if (isDisallowedExtension || isDisallowedMimeType) {
          return false;
        }

        if (acceptableTypes.some(type => type === '*')) {
          return true;
        }

        return acceptableTypes.some(regExp => new RegExp(regExp, 'i').test(file.type));
      });

      if (!isAllowedType) {
        this.addNotification({
          message: `You should upload only acceptable type ${this.acceptTypes}. HTML files are not allowed.`,
          title: 'Error',
          status: 0
        });
        return;
      }

      this.uploadedFiles = arrayFiles;
    },
    onSubmit (event) {
      this.onLoadHandler(event.target.files);
    },
    openUploadDialog () {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
    toggleClipboard (turnOn: boolean) {
      if (turnOn) {
        document.addEventListener('paste', this.pasteHandler);
        return;
      }

      document.removeEventListener('paste', this.pasteHandler);
    },
    async pasteHandler (event: ClipboardEvent) {
      const items = event.clipboardData?.items || [];
      const files: File[] = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].kind !== 'file') {
          continue;
        }

        const file: Nullable<File> = items[i].getAsFile();

        if (file) {
          files.push(file);
        }
      }

      if (files.length) {
        this.onLoadHandler(files);
      }
    },
    setImageUrl (files: File[]) {
      const maxSize = 60 * 1024 * 1024; // 60 - MB
      const urls: string[] = [];
      const bigFilesNames: string[] = [];

      files.forEach(file => {
        if (file.size > maxSize) {
          bigFilesNames.push(file.name);
          return;
        }

        const url = URL.createObjectURL(file);
        urls.push(url);
      });

      if (bigFilesNames.length > 0) {
        this.addNotification({
          message: `You should upload only files less than ${maxSize} MB. Big files: ${bigFilesNames.join(', ')}`,
          title: 'Error',
          status: 0
        });
        return;
      }

      if (urls.length) {
        this.$emit('onUpload', { urls, files: this.uploadedFiles });
      }
    }
  }
});
