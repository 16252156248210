export * from './interfaces';

export enum DealTransactionTypeEnum {
  sale = 'sale',
  lease = 'lease',
  consulting = 'consulting'
}

export enum DealInvoiceDueTypesEnum {
  on_receipt = 'on_receipt',
  on_10 = 'on_10',
  on_15 = 'on_15',
  on_30 = 'on_30',
}

export type IDeal = any;
