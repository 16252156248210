const KEYS_WITH_MAP: string[] = [
  'company',
  'contact',
  'deal',
  'property',
  'availability',
  'comparable',
  'pipeline',
  'occupier'
];

const LIST_KEYS = [
  ...KEYS_WITH_MAP,
  'eblast',
  'marketingCampaign',
  'signList',
  'request',
  'pressrelease',
  'project',
  'requirement',
];

const getMapDefaultSettings = () => ({
  sideBarWidth: '50%',
});

const getDefaultValue = () => ({
  lastAddToSetIds: [],
})

export function getInitialState () {
  const state = {};

  LIST_KEYS.forEach(key => {
    if (KEYS_WITH_MAP.includes(key)) {
      state[key] = Object.assign({}, {
        ...getMapDefaultSettings(),
        ...getDefaultValue(),
      });
      return;
    }

    state[key] = Object.assign({}, getDefaultValue());
  });

  return state;
}
