









































































































import { mapGetters } from 'vuex';
import { appPanelName } from '@/bundles/Panels/helpers';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { IGetAccountRouteHandler } from '@/bundles/App/helpers';

import ProfileMenuWrapper from '@/bundles/Panels/components/ProfileMenuWrapper.vue';
import MarketingTopPanelSendButtons from '@/bundles/Marketing/components/MarketingTopPanelSendButtons.vue';

export default mixins(AccountLayoutMixin).extend({
  name: 'AppTopPanel',

  components: {
    MarketingTopPanelSendButtons,
    ProfileMenuWrapper,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    allowFullscreen: Boolean,
    isSlides: Boolean,
    sourceType: {
      type: String,
      default: '',
    },
    allowEblast: Boolean,
    pressReleaseStatus: {
      type: String,
      default: ''
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    extensionHeight: {
      type: Number,
      default: 48
    },
    allowSendTest: Boolean
  },

  computed: {
    ...mapGetters('Filters', {
      getFilterId: 'getFilterId',
      getLastSelectedType: 'getLastSelectedType'
    }),
    getUserFullName: function (): string {
      return this.$store.state.user?.name || this.$store.state.user?.profile?.first_name || '';
    },
    currentPageName: function (): string {
      return this.sourceType === 'project' ? 'Presentations' : appPanelName[this.sourceType];
    },
    showNavButton (): boolean {
      return this.$vuetify.breakpoint.xsOnly && (this.isSpatialSearch || this.sourceType === 'dashboard');
    },
    isSpatialSearch (): boolean {
      return this.title === 'Spatial GIS';
    }
  },

  created () {
    this.$eventBus.$on('go:back-to-list', this.goBackToList);
  },

  beforeDestroy () {
    this.$eventBus.$off('go:back-to-list', this.goBackToList);
  },

  methods: {
    sendEblastCampaign () {
      this.$emit('sendCampaign')
    },
    sendEblastTest () {
      this.$emit('sendTest')
    },
    addNewSlide () {
      this.$emit('addNewSlide')
    },
    goFullScreen () {
      this.$emit('goFullScreen', true)
    },
    toggleMobileMenu: function () {
      this.$store.state.nav = !this.$store.state.nav;
    },
    getBackToListLinkParams (): IGetAccountRouteHandler {
      const type = this.getLastSelectedType(this.sourceType);
      if (this.sourceType === 'project') {
        return this.getAccountRoute({ name: 'projects' });
      }
      if (this.sourceType === 'pressrelease') {
        return this.getAccountRoute({ name: 'PressReleases' });
      }

      if (this.sourceType === 'eblast') {
        return this.getAccountRoute({ name: 'marketing-eblasts' });
      }
      if (this.sourceType === 'marketingCampaign') {
        return this.getAccountRoute({ name: 'marketing-campaigns' });
      }
      if (this.sourceType === 'marketingTemplates') {
        return this.getAccountRoute({ name: 'marketing-templates' });
      }
      if (this.sourceType === 'customRows') {
        return this.getAccountRoute({ name: 'custom-rows' });
      }
      switch (type) {
        case 'savedSet':
        case 'defaultFilter':
          const queryName = 'set';
          return this.getAccountRoute({
            name: this.currentPageName,
            query: { [queryName]: this.getFilterId(this.sourceType) }
          });
        case 'all':
          return this.getAccountRoute({
            name: this.currentPageName,
            query: { allRecords: 'true' }
          })
        default:
          return this.getAccountRoute({
            name: this.currentPageName,
          })
      }
    },
    goBackToList () {
      this.$router.push(this.getBackToListLinkParams());
    }
  }
});
