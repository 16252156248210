import * as constants from './lists-types';

export default {
  [constants.SET_SIDEBAR_WIDTH] (state, { width, type }) {
    state[type].sideBarWidth = width;
  },
  [constants.SET_LAST_ADD_TO_SET_IDS] (state, { ids, type }) {
    state[type].lastAddToSetIds = ids;
  },
}
